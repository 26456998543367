import logoLight from "@/images/Reduced-Logo-WHT.png";
import logoDark from "@/images/Reduced-Logo-BLK.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};

export const NavLinks = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Services",
    url: "/service",
    subItems: [
      {
        name: "Web Design & Development",
        url: "/web-design-and-development",
        // subSubItems: [
        //   {
        //     name: "App Design",
        //     url: "/service-appDesign",
        //   },
        //   {
        //     name: "Social Media",
        //     url: "/service-socialMedia",
        //   },
        //   {
        //     name: "UX Design",
        //     url: "/service-ux-design",
        //   },
        //   {
        //     name: "UI Planning",
        //     url: "/service-ui-planning",
        //   },
        //   {
        //     name: "UX Testing",
        //     url: "/service-ux-testing",
        //   },
        // ],
      },
      {
        name: "E-Commerce",
        url: "/ecommerce",
      },
      {
        name: "Social Media",
        url: "/social-media",
      },
      {
        name: "SEO & PPC",
        url: "/seo-ppc",
      },
      
    ],
  },
  {
    name: "Portfolio",
    url: "/portfolio",
    subItems: [
      {
        name: "255 | Creative", //this will be each project by name
        url: "/255-portfolio-details",
      },
      // {
      //   name: "Mark Anthony", //this will be each project by name
      //   url: "/portfolio-details",
      // },
      // {
      //   name: "Crawford & Kidd Details", //this will be each project by name
      //   url: "/portfolio-details",
      // },
    ],
  },
  // {
  //   name: "Blog",
  //   url: "/blog",
  //   subItems: [
  //     {
  //       name: "Blog 01",
  //       url: "/blog",
  //     },
  //     {
  //       name: "Blog 02",
  //       url: "/blog-2",
  //     },
  //     {
  //       name: "Blog 03",
  //       url: "/blog-3",
  //     },
  //     {
  //       name: "Blog Details",
  //       url: "/blog-single",
  //     },
  //   ],
  // },
  {
    name: "Contact",
    url: "/contact",
  },
];

import sliderOne01 from "@/images/slider/1_1.jpg";
import sliderOne02 from "@/images/slider/1_2.jpg";

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB \n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderOne02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB \n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

import sliderTwo01 from "@/images/custard_hero-min.jpg";
import sliderTwo02 from "@/images/slider/2_2.jpg";
import sliderTwo03 from "@/images/slider/2_3.jpg";


export const SliderTwoData = [
  {
    image: sliderTwo01,
    subTitle: "Welcome to Custard Digital",
    title: "establish your businesses digital presence",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  }
];

import sliderThree01 from "@/images/slider/3_1.jpg";
import sliderThree02 from "@/images/slider/3_2.jpg";

export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
  {
    image: sliderThree02,
    subTitle: "WELCOME TO MEIPALY AGENCY",
    title: "SMART WEB\n DESIGN AGENCY.",
    button: {
      label: "DISCOVER MORE",
      url: "/about",
    },
  },
];

export const ContactInfosBlock = {
  subTitle: "our offices",
  title: "offices near you",
  description:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const ContactInfosList = [
  {
    title: "austin",
    infos: [
      {
        name: "22 Texas West Hills",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "boston",
    infos: [
      {
        name: "5 Federal Street Boston",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "new york",
    infos: [
      {
        name: "8th Broklyn New York",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
  {
    title: "baltimore",
    infos: [
      {
        name: "3 Lombabr 50 baltimore",
      },
      {
        name: "needhelp@meipaly.com",
      },
      {
        name: "666 888 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Contact us",
  title: "write us a message",
  description:
    "Have a question? Our team is always happy to help you with any issues or questions you have. \n We aim to respond to all enquires within 24hrs.",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/portfolio/255portfolio1-min.png";
import portfolio02 from "@/images/portfolio/markanthony-1-min.png";
import portfolio03 from "@/images/portfolio/Crawford&kid-min.png";
// import portfolio04 from "@/images/portfolio/255-creative2.png";
// import portfolio05 from "@/images/portfolio/3 .jpg";
// import portfolio06 from "@/images/portfolio/6.jpg";

export const PortfolioData = [
  {
    title: "255 | Creative",
    categories: ["Website design", "development", "graphic design"],
    image: portfolio01,
    url: "/255-portfolio-details",
  },
  {
    title: "Mark Anthony",
    categories: ["Website design", "development", "branding", "marketing"],
    image: portfolio02,
    // url: "/portfolio-details",
  },
  {
    title: "Crawford & Kidd",
    categories: ["Website design", "development", "seo", "social", "branding"],
    image: portfolio03,
    // url: "/portfolio-details",
  },
  // {
  //   title: "255 | Creative",
  //   categories: ["..."],
  //   image: portfolio04,
  //   url: "/portfolio-details",
  // },
  // {
  //   title: "Mark Anthony",
  //   categories: ["..."],
  //   image: portfolio05,
  //   url: "/portfolio-details",
  // },
  // {
  //   title: "Crawford & Kidd",
  //   categories: ["..."],
  //   image: portfolio06,
  //   url: "/portfolio-details",
  // },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "design" },
  { name: "development" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "Design Styles",
  text:
    " Tincidunt elit magnis nulla facilisis sagittis sapien nunc amet ultrices dolores sit ipsum velit purus aliquet massa fringilla leo orci. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci dolors sit amet elit amet. ",
  client: "255 Creative",
  categories: [
    {
      name: "Graphic,",
      url: "#",
    },
    {
      name: "Illustrations,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};
 
import portfolioD03 from "@/images/portfolio/255 portfolio1.png";
import portfolioD04 from "@/images/portfolio/255portfolio22.png";

export const TwoFiveFivePortfolioDetailsData = {
  gallery: [{ image: portfolioD03 }, { image: portfolioD04 }],
  title: "Project Overview",
  text:
    "On this project, the client had already established their brand identity and had a good understanding of the functionality the website needed. Our tasks were to advise them on how to improve and best achieve their online goals, create graphic assets for the website, and design and develop the website. ",
  client: "255 Creative",
  categories: "Design, Development, Graphic Design",
  // date: "8 Nov, 2018",
  socials: [
    {
      name: "Visit 255 Creative",
      url: "https://www.255creative.me/",
    },
    
  ],
};

export const ServicePostData = [
  {
    title: "Web  Development",
    text:
      "We use industry-leading techniques and frameworks to create powerful, optimized, business tools.",
    iconName: "mei-settings",
    url: "/web-design-and-development",
  },
  {
    title: "e-Commerce",
    text:
      "Make your eCommerce store an online success. Increase your ROI and improve conversion rates!",
    iconName: "mei-transfer",
    url: "/ecommerce",
  },
  {
    title: "Marketing Strategy",
    text:
      "Marketing Strategy is the key to success. Our team of professionals will pave your road to success. ",
    iconName: "mei-pie-chart",
    url: "/seo-ppc",
  },
];

export const ServiceHomeTwoData = {
  subTitle: "Our Services",
  title: "We can elevate your business \n and maximise your ROI!",
  text:
    "Our expert team is equipt with the skillset to ensure your business reaches its \n full potential.  ",
};

export const ServiceHomeThreeData = {
  subTitle: "Services we are offering",
  title: "Our Services",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Transform your existing online business \n into an industy leading brand",
    subTitle: "Improve existing digital identity",
    text:
      "We dont just work with new companies, \n we can also take existing companies to the next level.",
  },
  posts: [
    {
      title: "Graphic Design",
      iconName: "mei-web-design",
      url: "/web-design-and-development",
    },
    {
      title: "SEO & Content Writing",
      iconName: "mei-computer-graphic",
      url: "/seo-ppc",
    },
    {
      title: "Digital Marketing",
      iconName: "mei-development-1",
      url: "/seo-ppc",
    },
    {
      title: "social media management",
      iconName: "mei-app-development",
      url: "/social-media",
    },
    {
      title: "Brand Strategy",
      iconName: "mei-development",
      url: "/seo-ppc",
    },
  ],
};

import serviceOne01 from "@/images/home_1/webdev-min.png";
import serviceOne02 from "@/images/home_1/digitalmarketing-min.png";
import serviceOne03 from "@/images/home_1/socialmedia-min.png";

export const ServicePostThreeData = {
  sectionContent: {
    title: "Tomorrow is made today",
    subTitle: "what we do",
    text:
      "Let’s Go Forward Together. See how our team can help you to achieve your goals and accelerate your business.  ",
  },
  posts: [
    {
      title: "web development",
      image: serviceOne01,
      url: "/web-design-and-development",
    },
    {
      title: "digital marketing",
      image: serviceOne02,
      url: "/seo-ppc",
    },
    {
      title: "Social Media Management",
      image: serviceOne03,
      url: "/social-media",
    },
  ],
};

export const FunfactData = [
  {
    title: "Projects Completed",
    countNumber: 705,
  },
  {
    title: "Active Clients",
    countNumber: 480,
  },
  {
    title: "Cups of Coffee",
    countNumber: 626,
  },
  {
    title: "Happy Clients",
    countNumber: 774,
  },
];

import trustClient01 from "@/images/home_1/4.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "We are trusted by more than 8900 clients",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "they trust us",
    subTitle: "our clients",
    text:
      "Dont just take our word for it \n here are some of our previous happy customers.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    {
      url: "#",
      image: ClientCarousel03,
    },
    {
      url: "#",
      image: ClientCarousel04,
    },
    {
      url: "#",
      image: ClientCarousel05,
    },
  ],
};

import aboutOne01 from "@/images/about/aboutus-page255.png";
import aboutOne02 from "@/images/about/aboutus-pageMylife.png";

export const AboutOneData = {
  sectionContent: {
    title: "Beautiful Custom Websites that keep your clients engaged!",
    subTitle: "get to know us",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Coffee's Consumed",
    number: 8745,
  },
};

import team01 from "@/images/team/team01.png";
import team02 from "@/images/team/team02.png";
import team03 from "@/images/team/team04.png";
import team04 from "@/images/team/team03.png";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "Our Experts",
    subTitle: "meet the team",
    text:
      "Meet our fantastic team that makes this all possible. Our team has a diverse set of skills that allows us to complete a variety of tasks. ",
  },
  posts: [
    {
      image: team01,
      name: "Jake Stubbs",
      designation: "Head Of Development",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team02,
      name: "Benjamin Stubbs",
      designation: "Project Manager",
      url: "",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team03,
      name: "Maddie Gray",
      designation: "Head Of Design",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Damien Harris",
      designation: "Head Of Marketing",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    // {
    //   image: team05,
    //   name: "Philip Hansen",
    //   designation: "Co Founder",
    //   url: "#",
    //   socials: [
    //     {
    //       name: "Facebook",
    //       url: "#",
    //     },
    //     {
    //       name: "Twitter",
    //       url: "#",
    //     },
    //     {
    //       name: "Linkedin",
    //       url: "#",
    //     },
    //   ],
    // },
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

export const SubscribeFormData = {
  sectionContent: {
    title: "Subscribe us",
    subTitle: "don’t miss out our latest updates",
  },
};

import testimonial01 from "@/images/home_1/ProfileIcon.png";
import testimonial02 from "@/images/home_1/ProfileIcon.png";
import testimonial03 from "@/images/home_1/ProfileIcon.png";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "What our clients say",
    Title: "Testimonials",
  },
  posts: [
    {
      name: "Mark Rolfe",
      designation: "MarkAnthony",
      image: testimonial01,
      content:
        "These guys were great, they really helped me through the whole process. They gave me such great advice and I couldn't be happier with the results. I can't recommend them enough.",
      date: "18 Mar, 2019",
    },
    {
      name: "AJ Lestrade",
      designation: "255 Creative",
      image: testimonial02,
      content:
        "I knew my business needed a website but after trying to create one myself using all the DIY site builders they just never seemed to be quite what I wanted. Now, after working with the team at Custard my website is perfect and is always fast. ",
      date: "25 June, 2020",
    },
    {
      name: "Mittie Flores",
      designation: "Crawford & Kidd",
      image: testimonial03,
      content:
        "Thank you for all your hard work. I'm sooo happy with everything and it has really helped my business grow over the last few months. I know I will be using you again soon.",
      date: "12 April, 2021",
    },
    // {
    //   name: "Carrie Sims",
    //   designation: "Director",
    //   image: testimonial01,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
    //   date: "25 nov, 2018",
    // },
    // {
    //   name: "Anne Stone",
    //   designation: "CO Founder",
    //   image: testimonial02,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
    //   date: "25 nov, 2018",
    // },
    // {
    //   name: "Hunter Brewer",
    //   designation: "Manager",
    //   image: testimonial03,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
    //   date: "25 nov, 2018",
    // },
    // {
    //   name: "Carrie Sims",
    //   designation: "Director",
    //   image: testimonial01,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
    //   date: "25 nov, 2018",
    // },
    // {
    //   name: "Anne Stone",
    //   designation: "CO Founder",
    //   image: testimonial02,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
    //   date: "25 nov, 2018",
    // },
    // {
    //   name: "Hunter Brewer",
    //   designation: "Manager",
    //   image: testimonial03,
    //   content:
    //     "This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum",
    //   date: "25 nov, 2018",
    // },
  ],
};

import featureTab01 from "@/images/home_1/website-min.png";
import featureTab02 from "@/images/home_1/handshake-min.png";
import featureTab03 from "@/images/home_1/marketing-min.png";

export const FeatureTabData = {
  sectionContent: {
    title: "why choose us",
    subTitle: "our benefits",
    text:
      "What you can expect when you team up with us. \n We promise to always provide a top-quality service to our clients that gets you results.",
  },
  posts: [
    {
      title: "Websites & Apps",
      content:
        "We are commited to creating beautiful, powerful, modern websites & apps that keep your users engaged. To ensure that every project we complete meets our standard, they all go through a quality control process before they are finished. Each one is checked agaist our checklist, consisting of custom criteria specificaly relating to your needs and requirments. As well as commmon critria, such as, great user experience, fast loading times and preforms without any issues.  ",
      image: featureTab01,
      lists: [
        {
          item: "Fast",
        },
        {
          item: "Designed using industry-leading techniques",
        },
        {
          item: "Developed using the latest technologies and techniques",
        },
        {
          item: "Great user experience, user engagement & functionality",
        },
        {
          item: "Fully Responsive",
        },

      ],
    },
    {
      title: "Ongoing Support",
      content:
        "When you work with us we understand that your website needs to continue to grow with your business and adapt with you. We also know how important it is that your website always performs at optimal levels with minimal interruptions. That's why we offer continued support, so your business is always online and reaching your customers. ",
      image: featureTab02,
      lists: [
        {
          item: "Support and advice from our talented team",
        },
        {
          item: "Dedicated account management",
        },
        {
          item: "24 Hour Ticketing system with a guaranteed response time within 12 hours",
        },
        {
          item: "Quick response to any issues that arise",
        },
        // {
        //   item: "Updates to CMS & Plugins",
        // },
        {
          item: "Full backups",
        },
      ],
    },
    {
      title: "Marketing",
      content:
        "Every business is only as good as its marketing team. Our team of experts are specialists in getting your services and products in front of the right people. Allow us to build you a powerful and memorable brand identity, plan a professional digital strategy and manage your social media. Let's improve your ROI.  ",
      image: featureTab03,
      lists: [
        {
          item: "Branding",
        },
        {
          item: "Social media management & marketing",
        },
        {
          item: "Digital Strategy",
        },
        {
          item: "PPC & marketing campaigns ",
        },
        {
          item: "Content Creation",
        },
        {
          item: "SEO",
        },
      ],
    },
  ],
};

export const ParallaxOneData = {
  iconName: "mei-team",
  title: "Great things in business are never done by one person.",
  specialText: "They’re done by a team of people.",
  text:
    "We are committed to providing our customers with exceptional service while \n offering our employees the best training.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "our portfolio",
    subTitle: "Here’s some of our recent projects",
    text:
      "Here are some of the recent projects that we have been working on.\n We understand that every project we take on is unique, therefore, all of the work we do is custom and completely tailored to you!",
  },
};

import video02 from "@/images/home_1/3.jpg";

export const VideoTwoData = {
  sectionContent: {
    title: "Digital Experience",
    subTitle: "how do we works",
    text:
      "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
  },
  video: {
    title: "Watch Video",
    ID: "y2Eqx6ys1hQ",
    image: video02,
  },
};

import aboutTwo01 from "@/images/about-custard4.jpg";
import aboutTwo02 from "@/images/about-custard3.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "Great website's make great businesses!",
    subTitle: "Here's A little bit about us",
    text:
      "We are committed to providing our customers with exceptional Website's & Apps that, not only look great, but serve as a business tool for success. We ensure that every project we work on always performs, so you can continue to deliver your products and services to your customers.",
  },
  button: {
    label: "Learn More",
    url: "/about",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "real experience",
    subTitle: "our core features",
    text:
      " We are committed to providing our customers with exceptional service while offering our employees the best training. ",
  },
  posts: [
    {
      title: "No Coding Skills Require",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "Online Documentation",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
    {
      title: "SEO Optimized",
      text:
        "There are many variations of passages of lorem ipsum available, but the majority have suffered.",
    },
  ],
  image: {
    text: "Total design freedom \n for everyone.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "View our Recent Work",
    url: "/portfolio",
  },
  {
    label: "Talk To Our Team",
    url: "/contact",
  },
];
